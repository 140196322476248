import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import { Container } from 'reactstrap'

// import HomePage from "../components/HomePage/HomePage"

import "../scss/custom.scss"

const IndexPage = () => (
  <div style={{overflowX:'hidden'}}>
    <Layout>
      <SEO title="Home" />
      {/*<HomePage />*/}
      <Container className="p-5">
        <div className="payment-page-container text-center">
          This is a placeholder page for the <br /> payment processing (UnionPay / Stripe) part
        </div>
      </Container>

    </Layout>
  </div>
)

export default IndexPage
